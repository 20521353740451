#navigationbar {
    @media screen and (max-width: 480px) {
        nav {
            justify-content: center !important;
        }
        nav ul {
            flex-direction: row !important;
        }
        li {
            font-size: 15px;
        }
        #intro {
            margin-top: 4rem;
        }
    }
    li {
        font-size: 35px;
        color: #0277b5;
        // border: 3px solid #0277b5;
    }
    .fa {
        text-decoration: none;
        color: #0277b5;
        background-color: #9bd3f4;
        margin-right: 1rem;
        border: 2px solid #f29c33;
        border-radius: 50%;
        padding: 0.5em;
    }
    .fa:hover {
        color: #272838;
        background-color: #f29c33;
    }
}
