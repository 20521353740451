#bestsellerssStyle {
    background-color: #9bd3f4;

    body {
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
            "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
        background-color: #9bd3f4;
    }

    h1 {
        text-align: center;
        font-weight: 1000;
    }
    h2 {
        color: green;
        margin: 20px;
    }
    h3 {
        font-weight: 1000;
        text-transform: capitalize;
    }
    .card {
        border: none;
        border-radius: 90%;
        background-color: rgb(195, 192, 238);
        margin-top: 2rem;
    }
    .card:hover {
        background-color: rgb(234, 226, 149);
        transition: background-color 0.7s ease-in-out;
    }

    @media screen and (min-width: 768px) {
        .img-fluid {
            width: 60%;
        }
    }
    .img-fluid {
        max-width: 100%;
        height: auto;
    }
    #dropdown {
        display: block;
    }

    @keyframes slidein {
        from {
            transform: translateX(-300%);
        }
        to {
            transform: translateX(0%);
        }
    }

    .slideImage {
        animation: slidein 3s ease-in;
        /* animation-iteration-count: infinite; */
        /* transform: translateX(-300%); */
    }
    .slider {
        padding-top: 20px;
        width: 250px;
        height: 33vh;
        position: relative;
        overflow-x: hidden;
    }
    .slides {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        object-fit: cover;
        transition: transform 0.4s ease-in-out;
    }

    .slides img {
        margin-left: 10px;
        margin-right: 10px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .arrow {
        justify-content: center;
        text-shadow: 5px 5px 10px rgb(156, 156, 167, 0.5);
        margin: 50px;
    }
    .arrow span {
        text-align: center;
    }

    .arrow:hover {
        text-shadow: 5px 5px 10px rgb(55, 55, 187);
        cursor: pointer;
    }
}
