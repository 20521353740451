#homepage {
    background-color: #9bd3f4;

    #intro {
        color: #0277b5;
        height: 100vh;
    }

    @media screen and (max-width: 480px) {
        #headshot {
            max-width: 40%;
        }
        #intro {
            padding-top: 0px;
            // height: 3rem;
        }
    }

    @keyframes name {
        from {
            color: #9bd3f4;
        }
        to {
            color: #0277b5;
        }
    }
    @keyframes lineAnimation {
        100% {
            width: 80%;
        }
    }
    #line {
        width: 1px;
        border-bottom: 3px solid #f29c33;
        animation: lineAnimation;
        animation-fill-mode: forwards;
        animation-duration: 2s;
    }

    #intro h1 {
        font-size: 5rem;
        animation: name;
        animation-duration: 3s;
        animation-fill-mode: forwards;
    }
    h2 {
        line-height: 3rem;
    }
    #intro h2 {
        color: #9bd3f4;
        text-align: center;
        font-size: 2.5rem;
        padding-top: 10px;
        animation: name;
        animation-duration: 2s;
        animation-fill-mode: forwards;
    }
    #intro h2:first-of-type {
        animation-delay: 1s;
    }
    #intro h2:nth-of-type(2) {
        animation-delay: 1.5s;
    }
    #intro h2:nth-of-type(3) {
        animation-delay: 2s;
    }
    #arrowDown {
        color: #9bd3f4;

        font-size: 75px;
        position: absolute;
        bottom: 30px;
        left: calc(50% - 37.5px);
        animation: name;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-delay: 2.5s;
    }
    @media screen and (max-width: 480px) {
        #intro h1 {
            font-size: 2.7rem;
        }
        #intro h2 {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
    #intro code {
        font-size: 1.75rem;
    }
    .yellow-ul {
        text-decoration: underline;
        text-decoration-color: #f29c33;
        text-underline-offset: 0.4em;
    }

    .about {
        color: #0277b5;
        padding: 30px;
    }
    .about h5 {
        line-height: 200%;
        color: #545454;
    }
    @media screen and (max-width: 480px) {
        #about {
            padding-top: 6rem;
        }
    }

    #valuescollage {
        //
    }

    #skills {
        line-height: 3rem;
        color: #272838;
        font-size: 1.3rem;
    }
    @media screen and (max-width: 480px) {
        #skills {
            line-height: 1.6rem;
            font-size: 1.1rem;
        }
    }

    form input {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    form textarea {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
    }

    form .btn-primary {
        width: 100%;
        margin-top: 15px;
        background-color: #124559;
    }

    #portfolio {
        color: #0277b5;
        padding: 30px;
        margin-top: 20px;
        padding-bottom: 40px;
    }

    .btn-outline-primary {
        color: #0277b5;
        font-weight: 700;
        border-color: #f29c33;
        border-width: 2px;
    }

    .btn-outline-primary:hover {
        color: #272838;
        background-color: #f29c33;
    }

    .contactbutton {
        color: #272838;
        background-color: #f29c33;
    }

    .contactbutton:hover {
        color: #f29c33;
        background-color: #272838;
    }

    .card {
        background-color: transparent;
        color: #0277b5;
        border-color: transparent;
    }
    .card-title {
        color: black;
    }
    .card-body p {
        color: black;
    }

    footer {
        background-color: black;
        color: white;
        width: 100%;
    }
    main {
        background-color: #064943;
        color: white;
        padding: 30px;
    }
    main img {
        border-radius: 10px;
    }
    .stack {
        background-color: black;
        color: white;
        padding: 3px;
    }
}
